import qs from 'qs'
import { navigate } from '@reach/router'
import { add, format, isValid } from 'date-fns'

const API_ROOT_URL = process.env.GATSBY_API_ROOT_URL

export const GRAPHQL_SERVER_URL = API_ROOT_URL + '/api/graphql'
export const LOGIN_PATH = API_ROOT_URL + '/login/island-loh'
export const LOGOUT_PATH = API_ROOT_URL + '/api/logout'

export const getLoginPath = backTo => {
  return `${LOGIN_PATH}?backTo=${encodeURIComponent(backTo)}`
}

export const getInsurancePeriod = periodStart => {
  if (!isValid(new Date(periodStart))) {
    return ''
  }
  const periodEnd = add(new Date(periodStart), { years: 1 })
  return `${format(new Date(periodStart), 'dd.MM.yyyy')} - ${format(
    periodEnd,
    'dd.MM.yyyy'
  )}`
}

export const formatPriceString = (price, verbose) => {
  const formatted = price.toFixed(0).replace(/(\d)(?=(\d{3})+\b)/g, '$1.')
  return `${formatted}${verbose ? ' kr.' : ''}`
}

export const formatDate = date => {
  date = new Date(date)
  return [date.getDate(), date.getMonth() + 1, date.getFullYear() % 100]
    .map(i => i < 10 ? '0' + i : i)
    .join('.')
}

export const mergeQueryParams = (newParams, { replace = true } = {}) => {
  const params = qs.parse(window.location.search.substring(1))
  const s = qs.stringify({ ...params, ...newParams }, { arrayFormat: 'repeat' })
  navigate(window.location.pathname + (s && '?' + s), { replace })
}

export const pluralize = (n, singular, plural) => {
  return n % 10 === 1 && n % 100 !== 11 ? singular : plural
}

export const redirect = (action, params = [], { method = 'POST' } = {}) => {
  const form = document.createElement('form')
  form.action = action
  form.method = method
  document.body.appendChild(form)

  params.forEach(([name, value]) => {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = name
    input.value = value
    form.appendChild(input)
  })

  form.submit()
}

export const chunkArray = (array, size) => {
  const chunkedArr = []
  for (let i = 0; i < array.length; i += size) {
    chunkedArr.push(array.slice(i, i + size))
  }
  return chunkedArr
}
